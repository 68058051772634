import React from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import styles from './TitleAndTagline.st.css';
import { AlignmentOptions } from '../../types';

interface TitleAndTaglineProps
  extends InjectedExperimentsProps,
    InjectedTranslateProps {
  titleAndTaglineAlignment: AlignmentOptions;
  serviceTitleVisibility: boolean;
  serviceTaglineVisibility: boolean;
}

// type WidgetProps = InjectedExperimentsProps &
//   InjectedTranslateProps &
//   TitleAndTaglineProps;

export default translate()(
  withExperiments<TitleAndTaglineProps>(
    ({
      t,
      experiments,
      titleAndTaglineAlignment,
      serviceTaglineVisibility,
      serviceTitleVisibility,
      ...rest
    }) => {
      const serviceTitle = () =>
        serviceTitleVisibility ? (
          <Text
            data-hook="title-and-tagline-title"
            className={styles.title}
            typography={TYPOGRAPHY.largeTitle}
            tagName="h2"
          >
            Italian Cooking Class
          </Text>
        ) : null;

      const serviceTagline = () =>
        serviceTaglineVisibility ? (
          <Text
            data-hook="title-and-tagline-tagline"
            className={styles.tagline}
            typography={TYPOGRAPHY.runningText}
          >
            Learn how to make Gnocchi and Ravioli at Home
          </Text>
        ) : null;

      return (
        <div
          {...styles('root', { alignment: titleAndTaglineAlignment }, rest)}
          data-hook="title-and-tagline-wrapper"
        >
          {serviceTitle()}
          {serviceTagline()}
        </div>
      );
    },
  ),
);
